<template>
  <footer class="app-footer">
    <!-- 联系我们 -->
    <div class="contact">
      <div class="container">
        <div class="container_content">
          <div class="left_content">
            <div class="code">
              <div class="code_content">
                <div class="code_txt"></div>
                <p class="suTake">商务洽谈</p>
              </div>
              <div class="code_content">
                <div class="code_txt1"></div>
                <p class="suTake">销售微信1</p>
              </div>
              <!-- <div class="code_content">
                <div class="code_txt2"></div>
                <p class="suTake">销售微信2</p>
              </div> -->
            </div>
          </div>
          <div class="right_content">
            <p class="company_name">长沙胜邦信息科技有限公司</p>
            <p>地址: 湖南省长沙市高新区中电软件园5栋4层</p>
            <p>备案号: 湘ICP备2023001059号-0</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang='less'>
.app-footer {
  overflow: hidden;
  background-color: #0d4596;
  padding-top: 20px;
  .contact {
    background: #0d4596;
    margin: 0 auto;
    .container {
      height: 170px;
      display: flex;
      .container_content{
        width: 940px;
        height: 200px;
        margin: 0 auto;
        display: flex;
        .left_content{
          flex: 0.5;
          border-right: 1px solid #ccc;
          .company_text{
            text-align: center;
            margin-top: 10px;
            font-size: 17px;
            color:white;
          }
          .company_address{
            text-align: center;
            margin-top: 10px;
            font-size: 17px;
            color:white;
          }
          .code{
            width: 380px;
            height: 140px;
            margin: 0 auto;
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            .code_content{
              width: 100px;
              height: 100px;
              .code_txt{
                width: 100px;
                height: 100px;
                border: 5px solid white;
                background: url('../assets/images/xia.jpg') no-repeat center / contain;
              }
              .code_txt1{
                width: 100px;
                height: 100px;
                border: 5px solid white;
                background: url('../assets/images/weixin.png') no-repeat center / contain;
              }
              .code_txt2{
                width: 100px;
                height: 100px;
                border: 5px solid white;
                background: url('../assets/images/code1.jpg') no-repeat center / contain;
              }
              .suTake{
                 text-align: center;
                 color: white;
                 font-size: 15px;
                 margin-top: 15px;
              }
            }
          }
        }
        .right_content{
          flex: 0.5;
          p:first-child{
            margin-left: 70px;
            margin-top: 20px;
            font-size: 20px;
            font-weight: bold;
            color: white;
          }
          p{
            margin-top: 20px;
            font-size: 16px;
            margin-left: 70px;
            color: white;
          }
        }
      }
    }
  }
  .copyright {
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: white;
    font-size: 18px;
    p {
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
}
</style>
