<template>
  <header class="app-header">
    <div class="container">
      <h3 class="logo"><RouterLink to="/">胜邦科技</RouterLink></h3>
      <!-- 使用头部导航组件 -->
      <AppHeaderNav />
      <!-- <div class="search">
        <i class="iconfont icon-search"></i>
        <input type="text" placeholder="搜一搜" />
      </div> -->
      <!-- 使用头部购物车组件 -->
      <!-- <AppHeaderCart /> -->
    </div>
  </header>
</template>

<script>
import AppHeaderNav from './app-header-nav'
/* import AppHeaderCart from './app-header-cart' */
export default {
  name: 'AppHeader',
  components: { AppHeaderNav/* AppHeaderCart */ }
}
</script>

<style scoped lang='less'>
.app-header {
  background: #0d4596;
  .container {
    width: 800px;
    display: flex;
    align-items: center;
  }
  .logo {
    width: 200px;
    a {
      display: block;
      margin-left: -190px;
      height: 80px;
      width: 100%;
      text-indent: -9999px;
      background: url(../assets/images/homelogo.png) no-repeat center 0px / contain;
    }
  }
}
</style>
