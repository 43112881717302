<template>
    <nav class="navbar">
        <ul>
            <li class="home">
                <a href="#">首页</a></li>
            <li>
                <RouterLink :to="`/product`"><a href="#">产品中心</a></RouterLink>
                <ul class="hide">
                    <li><RouterLink :to="`/nursing`"><a href="#">病房护理对讲系统</a></RouterLink></li>
                    <li><RouterLink :to="`/message`"><a href="#">信息发布系统</a></RouterLink></li>
                    <li><RouterLink :to="`/lineUp`"><a href="#">排队叫号系统</a></RouterLink></li>
                </ul>
            </li>
            <li>
                <RouterLink :to="`/cart`"><a href="#">关于我们</a></RouterLink></li>
            <li><RouterLink :to="`/goods`"><a href="#">联系我们</a></RouterLink></li>
        </ul>
    </nav>
</template>
<script>
import { RouterLink } from 'vue-router'
export default {
  name: 'AppHeaderNav',
  setup () {
    const newList = [
      { id: '1', name: '产品中心' },
      { id: '2', name: '敬请期待' },
      { id: '3', name: '敬请期待' },
      { id: '4', name: '敬请期待' },
      { id: '5', name: '敬请期待' }
    ]
    return { newList }
  },
  components: { RouterLink }
}
</script>
<style scoped lang="less">
/* css样式初始化 */
* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
    text-transform: capitalize;
    transition: .2s linear;
}

/* header样式初始化*/
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #e1e1e2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .logo {
    color: white;
    padding: 0 1rem;
}
/* 导航样式 */
.navbar ul {
    display: flex;
}

.navbar ul li {
    font-weight: bold;
    width: 150px;
    list-style: none;
    text-align: center;
}

.navbar ul li a {
    width: 140px;
    height: 60px;
    line-height: 60px;
    font-size: 17px;
    color: white;
    display: inline-block;
}

.navbar ul li a:hover {
    color: #b9bbb9;
}
.home_content{
    width: 40px;
    height: 60px;
    border: 1px solid red;
}
/* 二级菜单样式 */
.navbar ul li ul {
    position: absolute;
    display: none;
    width: 260px;
    line-height: 45px;
    z-index: 999;
}

.navbar ul li ul li {
    background-color: black;
}

/* 悬浮展开二级菜单 */
.navbar ul li ul li a:hover {
    color: white;
}

.navbar ul li ul li:hover {
    background-color: #0d4596;
}

.navbar ul li:hover ul {
    display: block;
}

/* 网站内容*/
.content {
    margin-top: 50px;
}
</style>
